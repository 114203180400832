.tab-calendar {
    font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    border: thin solid #EDEBE9;
    box-shadow: 0px 16px 18px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.tab-calendar .react-datepicker__header {
    background-color: #FFFFFF;
    border-bottom: none;
    padding: 0;
}

.tab-calendar .react-datepicker__day--selected {
    background-color: #5B5FC7;
}

.tab-calendar .react-datepicker__day--keyboard-selected {
    background-color: #5B5FC7;
}
