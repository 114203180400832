@tailwind base;
@tailwind components;
@tailwind utilities;

body, #root {
    margin: 0;
    min-height: 100vh;
    background-color: #F5F5F5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
